<template>
  <main-layout>

    <div class="hero">
      <div class="hero__img">
        <img v-lazy="`$/images/pouch-points/MainIMG_MockupPoints.png`" alt="Laptop">
      </div>
      <div class="cta cta--desktop-left-aligned">
        <h1 class="cta__title">
          <span class="cta__title--light">Shop, Earn</span><br><span class="cta__title--bold">&amp; Redeem Gift Cards.</span>
        </h1>
        <div class="cta__subtitle">Start collecting Pouch Points when you shop online, and reward yourself with Gift Cards from your favourite stores.</div>
        <div class="cta__button">
          <utm-itm-link class="button is-primary" :targetUrl="accountJoinUrl">
            Join &amp; Earn
          </utm-itm-link>
       </div>
        <div class="cta__stats">
          <p-rating class="cta__rating" size='6' /> - <span class="cta__stats--underline"><a href="https://uk.trustpilot.com/review/joinpouch.com" target="_blank">trusted by</a></span> over <span class="cta__stats--bold">200,000 Shoppers.</span>
        </div>
      </div>
    </div>

    <div class="benefits">
      <div class="intro">
        <span class="intro__subtitle">BENEFITS</span>
        <h2 class="intro__title"><span class="intro__title--brand">Join Pouch</span> for more benefits!</h2>
        <p class="intro__desc">Join the Pouch family and <span class="intro__desc--bold">earn Pouch Points</span> by shopping at participating retailers, completing certain tasks and referring your friends.</p>
      </div>
      <carousel
          :loop="true"
          :autoplay="true"
          :perPageCustom="[[320, 1], [768, 2], [1024, 3], [1366, 4]]"
          paginationColor="#DDDDDD"
          paginationActiveColor="#FF6441"
          centerMode="true"
        >
        <slide class="slide-collect-points">
          <div class="slide-card">
            <div class="slide-card__img">
              <img v-lazy="`$/images/pouch-points/benefits-2.svg`" alt="Collect Pouch Points on participating retailers.">
            </div>
            <div class="slide-card__desc">
              <span class="slide-card__desc--brand">Collect Pouch Points</span> on participating retailers.
            </div>
          </div>
        </slide>
        <slide class="slide-collect-points">
          <div class="slide-card">
            <div class="slide-card__img">
             <img v-lazy="`$/images/pouch-points/benefits-3.svg`" alt="Redeem your points for Gift Cards!">
            </div>
            <div class="slide-card__desc">
              <span class="slide-card__desc--brand">Redeem your points</span> for Gift Cards!
            </div>
          </div>
        </slide>
        <slide class="slide-collect-points">
          <div class="slide-card">
            <div class="slide-card__img">
              <img v-lazy="`$/images/pouch-points/favourite-brands.svg`" alt="Never miss a deal from your favourite brands.">
            </div>
            <div class="slide-card__desc">
              Never miss a deal from your <span class="slide-card__desc--brand">favourite brands.</span>
            </div>
          </div>
        </slide>
        <slide class="slide-collect-points">
          <div class="slide-card">
            <div class="slide-card__img">
              <img v-lazy="`$/images/pouch-points/benefits-4.svg`" alt="Refer your friends and earn 500 points.">
            </div>
            <div class="slide-card__desc">
              Refer your friends <span class="slide-card__desc--brand">and earn 500 points.</span>
            </div>
          </div>
        </slide>
      </carousel>
    </div>

    <div class="how-it-works">
      <div class="how-it-works__container">
        <div class="how-it-works__desc">
          <div class="intro">
            <span class="intro__subtitle">STEP BY STEP</span>
            <h2 class="intro__title">How easy it works.</h2>
          </div>
          <div class="how-it-works__steps">
            <div
              class="how-it-works__step"
              :class="{'how-it-works__step--active': activeStep === i }"
              v-for="(step, i) in steps"
              :key="i"
              @click="activeStep = i"
            >
              <h3 class="how-it-works__point"><span class="how-it-works__point--brand">{{ step.number }}.</span> {{ step.point }}</h3>
              <p v-if="step.number === '01' "
              class="how-it-works__details">Visit <a :href="accountJoinUrl">this page</a>{{ step.details }}</p>
              <p v-if="step.number === '02' || step.number === '04' "
              class="how-it-works__details">{{ step.details }}</p>
              <p v-if="step.number === '03' "
              class="how-it-works__details">{{ step.details }}<router-link :to="{path: 'terms-and-conditions'}" target='_blank'> T&C</router-link> apply.</p>
            </div>
          </div>
        </div>

        <div class="how-it-works__slides">
          <carousel
              :perPage="1"
              paginationColor="#DDDDDD"
              paginationActiveColor="#FF6441"
              :value="activeStep"
              @page-change="number => activeStep = number"
          >
            <slide v-for="(step, i) in steps" :key="i">
              <div class="how-it-works__slide">
                <div class="how-it-works__img">
                  <img v-lazy="`$/images/pouch-points/HowItWorks/HowItWorks_${step.number}.png?v1`" alt="">
                </div>
                <div class="how-it-works__bottom-info">
                  <h3 class="how-it-works__point"><span class="how-it-works__point--brand">{{ step.number }}.</span> {{ step.point }}</h3>
                  <p class="how-it-works__details">{{ step.details }}</p>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </div>

    <div class="reward">
      <div class="intro">
        <span class="intro__subtitle">SHOPPING REWARDS</span>
        <h2 class="intro__title">What are Pouch Points?</h2>
        <p class="intro__desc"><span class="intro__desc--bold">Pouch Points are rewards we give you when you shop online using Pouch</span> at participating retailers as well as completing certain tasks with us and entering fun competitions.
You can redeem these points for Gift Cards from your favourite retailers. <router-link :to="{ name: 'tandcs' }">T&amp;Cs</router-link> apply.</p>
        <div class="reward__cta reward__cta--only-desktop">
          <utm-itm-link class="button is-primary" :targetUrl="accountJoinUrl">
            Join &amp; Earn
          </utm-itm-link>
        </div>
      </div>
      <div class="reward__img">
        <img v-lazy="`$/images/pouch-points/GiftCard_Amazon&Nike.png?v1`" alt="Rewards">
      </div>
      <div class="reward__cta reward__cta--only-mobile">
        <utm-itm-link class="button is-primary" :targetUrl="accountJoinUrl">
          Join &amp; Earn
        </utm-itm-link>
      </div>
    </div>

    <div class="retailers">
      <div class="retailers__container">
        <div class="intro">
          <span class="intro__subtitle">OUR RETAILERS</span>
          <h2 class="intro__title"><span class="intro__title--brand">Pouch Points</span> work with your favourite participating retailers.</h2>
          <p class="intro__desc">Get rewarded when shopping online with us. <br><span class="intro__desc--bold">Here are our top picks!</span></p>
        </div>
        <div class="retailers__list">
          <carousel
              :loop="true"
              :autoplay="true"
              :perPageCustom="[[320, 2], [768, 4]]"
              :centerMode="true"
              paginationColor="#DDDDDD"
              paginationActiveColor="#FF6441"
          >
            <slide v-for="(logoGroup, i) in retailerLogos" :key="i">
              <div class="retailers__column">
                <div class="retailers__card" v-for="(logo, j) in logoGroup" :key="j">
                  <img v-lazy="`${logo}`" alt="">
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </div>

    <div class="cta cta--padding">
      <h1 class="cta__title">
        <span class="cta__title--light">Shop, Earn</span><br><span class="cta__title--bold">&amp; Redeem Gift Cards.</span>
      </h1>
      <div class="cta__button">
        <utm-itm-link class="button is-primary" :targetUrl="accountJoinUrl">
          Join &amp; Earn
        </utm-itm-link>
      </div>
      <div class="cta__stats">
        <p-rating class="cta__rating" size='6' /> - <span class="cta__stats--underline"><a href="https://uk.trustpilot.com/review/joinpouch.com" target="_blank">Trusted by</a></span> over <span class="cta__stats--bold">200,000 Shoppers.</span>
      </div>
    </div>

  </main-layout>
</template>

<script>
import config from '@/config'
import UtmItmLink from '@/views/partials/UtmItmLink.vue'

export default {
  components: { UtmItmLink },

  data: () => ({
    retailerLogos: [
      [
        '$/images/pouch-points/Retailers/01_Superdry_RetailerPoints.png',
        '$/images/pouch-points/Retailers/02_Wilko_RetailerPoints.png',
        '$/images/pouch-points/Retailers/03_Superdrug_RetailerPoints.png'
      ],
      [
        '$/images/pouch-points/Retailers/04_Travelodge_retailerPoints.png',
        '$/images/pouch-points/Retailers/05_Game_RetailerPoints.png',
        '$/images/pouch-points/Retailers/06_UnderArmour_RetailerPoints.png'

      ],
      [
        '$/images/pouch-points/Retailers/07_BQ_RetailerPoints.png',
        '$/images/pouch-points/Retailers/08_Dunelm_RetailerPoints.png',
        '$/images/pouch-points/Retailers/09_Footlocker_RetailerPoints.png'

      ],
      [
        '$/images/pouch-points/Retailers/10_Booking_RetailerPoints.png',
        '$/images/pouch-points/Retailers/11_Uniqlo_retailerPoints.png',
        '$/images/pouch-points/Retailers/12_UrbanOutfitters_RetailerPoints.png'
      ]
    ],
    steps: [
      {
        number: '01',
        point: 'Create an account.',
        details: ' and join with your email address. You will receive an email to confirm that you are signed up.'
      },
      {
        number: '02',
        point: 'Add our Browser Extension.',
        details: 'Choose your prefered browser and install our Browser Extension on your Desktop.'
      },
      {
        number: '03',
        point: 'Start saving & earning points.',
        details: 'Earn points when you shop at participating retailers. Collect a minimum of 1500 points to claim a £15 Gift Card.'
      },
      {
        number: '04',
        point: 'Redeem your points.',
        details: 'Use your Pouch Points to get Gift Cards from some of your favourite retailers!'
      }
    ],
    activeStep: 0,
    accountLoginUrl: config.accountLoginUrl,
    accountJoinUrl: config.accountJoinUrl
  }),

  computed: {},

  methods: {}
}
</script>

<style lang="sass" scoped>
.slide-collect-points
  flex-basis: 300px !important
  @media screen and ( max-width: 768px )
    flex-basis: inherit !important
.hero
  padding: 35px 24px 46px 24px
  background-image: url("/static/images/pouch-points/hero-wave.svg")
  background-repeat: no-repeat
  background-size:  cover
  background-position: bottom
  @media screen and ( min-width: 768px )
    background-image: url("/static/images/pouch-points/hero-wave-big.svg")
    display: flex
    flex-direction: row
    padding-bottom: 100px
  &__img
    margin: auto
    padding-bottom: 35px
    max-width: 267px
    @media screen and ( min-width: 768px )
      margin-right: 58px
      max-width: 660px

.cta
  margin: auto
  flex: 0 0 50%
  text-align: center
  max-width: 1440px
  @media screen and ( min-width: 768px )
    max-width: 562px
  &--padding
    padding: 60px 0
    @media screen and ( min-width: 1440px )
      padding-top: 0
  &--desktop-left-aligned
    @media screen and ( min-width: 768px )
      text-align: left
  &__title
    font-size: 52px
    line-height: 135%
    text-align: inherit
    color: #363636
    padding-bottom: 16px
    &--light
      font-weight: 300
    &--bold
      font-weight: 600
  &__subtitle
    text-align: inherit
    font-size: 16px
    line-height: 150%
    color: #7A7A7A
    padding-bottom: 24px
  &__button
    text-align: inherit
    padding-bottom: 16px
  &__rating
    display: inline
  &__stats
    text-align: inherit
    font-size: 12px
    line-height: 150%
    letter-spacing: 0.16px
    color: #575757
    &--underline
      text-decoration: underline
    &--bold
      font-weight: 600

.benefits
  padding: 35px 24px
  text-align: center
  max-width: 1440px
  margin: 0 auto

.how-it-works
  text-align: center
  padding: 0 20px
  margin: 50px 0
  background-color: #FDFBFB
  position: relative
  @media screen and ( min-width: 425px )
    margin: 100px 0
  @media screen and ( min-width: 1024px )
    margin: 200px 0
  @media screen and ( min-width: 2560px )
    margin: 400px 0
  &:before
    content: ''
    background-image: url("/static/images/pouch-points/how-it-works-wave.svg")
    background-repeat: no-repeat
    background-size:  cover
    background-position: top
    display: block
    height: 50px
    position: absolute
    left: 0
    right: 0
    top: -50px
    @media screen and ( min-width: 425px )
      height: 100px
      top: -100px
    @media screen and ( min-width: 1024px )
      height: 200px
      top: -200px
    @media screen and ( min-width: 2560px )
      height: 300px
      top: -300px
  &:after
    content: ''
    background-image: url("/static/images/pouch-points/how-it-works-wave.svg")
    background-repeat: no-repeat
    background-size:  cover
    background-position: bottom
    display: block
    position: absolute
    left: 0
    right: 0
    height: 50px
    bottom: -50px
    @media screen and ( min-width: 425px )
      height: 100px
      bottom: -100px
    @media screen and ( min-width: 1024px )
      height: 200px
      bottom: -200px
    @media screen and ( min-width: 2560px )
      height: 300px
      bottom: -300px
  &__container
    max-width: 1440px
    margin: 0 auto
    @media screen and ( min-width: 1024px )
      text-align: left
      display: grid
      grid-template-columns: 1fr 1fr
      /deep/ .VueCarousel-pagination
        display: none
      .intro
        margin: 0 0 24px 0
      .intro__title
        margin: 0
  &__desc
    @media screen and ( min-width: 1024px )
      flex: 1 0 50%
      margin-left: 16px
      order: 1
  &__steps
    display: none
    border-left: 1px solid rgba(54, 54, 54, 0.5)
    cursor: pointer
    @media screen and ( min-width: 1024px )
      display: block
  &__step
    padding-left: 84px
    max-width: 580px
    opacity: 0.5
    border-left: 3px solid transparent
    transition: all 0.5s
    &--active
      opacity: 1
      border-left-color: #FF6441
      @media screen and ( min-width: 1024px )
        .how-it-works__point--brand
          color: #FF6441
  &__slides
  &__slide
  &__img
    margin: 0 auto 24px auto
    max-width: 616px
  &__bottom-info
    @media screen and ( min-width: 1024px )
      display: none
  &__point
    font-weight: 600
    font-size: 16px
    line-height: 24px
    letter-spacing: 0.16px
    margin-bottom: 12px
    color: #7A7A7A
    position: relative
    @media screen and ( min-width: 1024px )
      margin-bottom: 8px
    &--brand
      color: #FF6441
      @media screen and ( min-width: 1024px )
        color: #7A7A7A
        position: absolute
        left: -32px
  &__details
    font-size: 16px
    line-height: 150%
    letter-spacing: 0.16px
    color: #7A7A7A
    margin-bottom: 24px

.intro
  max-width: 609px
  margin: 0 auto
  text-align: inherit
  &__subtitle
    font-size: 16px
    line-height: 18px
    letter-spacing: 2px
    color: #FF6441
    margin-bottom: 16px
    display: block
    text-align: inherit
  &__title
    font-weight: 600
    font-size: 24px
    line-height: 135%
    color: #363636
    display: block
    margin: 0 auto 16px auto
    text-align: inherit
    max-width: 474px
    @media screen and ( min-width: 1024px )
      font-size: 32px
    &--brand
      color: #FF6441
  &__desc
    font-size: 16px
    line-height: 150%
    letter-spacing: 0.16px
    color: #7A7A7A
    margin-bottom: 24px
    text-align: inherit
    &--bold
      font-weight: 600

.slide-card
  width: 268px
  height: 263px
  background: #FFFFFF
  box-shadow: 0px 2px 4px rgba(54, 54, 54, 0.08)
  border-radius: 8px
  margin: 14px auto
  padding: 15px 20px 0 20px
  position: relative
  &__img
    text-align: center
    margin-bottom: 10px
  &__desc
    text-align: center
    font-weight: 600
    font-size: 20px
    line-height: 150%
    letter-spacing: 0.16px
    color: #7A7A7A
    &--brand
      color: #FF6441
  .card-badge
    position: absolute
    top: 15px
    right: 20px

.reward
  padding: 35px 24px
  text-align: center
  max-width: 1440px
  margin: 0 auto
  @media screen and ( min-width: 768px )
    display: flex
    align-items: center
    text-align: left
  .intro__title
    margin-left: 0
  &__img
    margin: 0 auto 28px auto
    max-width: 516px
  &__cta
    text-align: inherit
    &--only-desktop
      @media screen and ( max-width: 767px )
        display: none
    &--only-mobile
      @media screen and ( min-width: 768px )
        display: none

.retailers
  padding: 0 24px
  text-align: center
  position: relative
  background-color: #FDFBFB
  margin: 50px 0
  @media screen and ( min-width: 425px )
    margin: 100px 0
  @media screen and ( min-width: 1024px )
    margin: 200px 0
  @media screen and ( min-width: 2560px )
    margin: 400px 0
  &:before
    content: ''
    background-image: url("/static/images/pouch-points/retailers-wave.svg")
    background-repeat: no-repeat
    background-size:  cover
    background-position: top
    display: block
    position: absolute
    left: 0
    right: 0
    height: 50px
    top: -50px
    @media screen and ( min-width: 425px )
      height: 100px
      top: -100px
    @media screen and ( min-width: 1024px )
      height: 200px
      top: -200px
    @media screen and ( min-width: 2560px )
      height: 300px
      top: -300px
  &:after
    content: ''
    background-image: url("/static/images/pouch-points/how-it-works-wave.svg")
    background-repeat: no-repeat
    background-size:  cover
    background-position: bottom
    display: block
    position: absolute
    left: 0
    right: 0
    height: 50px
    bottom: -50px
    @media screen and ( min-width: 425px )
      height: 100px
      bottom: -100px
    @media screen and ( min-width: 1024px )
      height: 200px
      bottom: -200px
    @media screen and ( min-width: 2560px )
      height: 300px
      bottom: -300px
  &__container
    max-width: 1440px
    margin: 0 auto
  &__list
    max-width: 1000px
    margin: 0 auto
  &__card
    background: #FFFFFF
    border-radius: 8px
    margin: 28px 14px
    height: auto
    min-width: 205px
    box-shadow: 0px 2px 4px rgba(54, 54, 54, 0.08)
    display: flex
    align-items: center
    justify-content: center
</style>
